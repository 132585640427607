import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import EventCard from './EventCard';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';

import { useLoadMore } from '../../lib/hooks';
import PageFooter from '../PageFooter';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';

const ControlledTabs = ({
  pastEvents,
  upcomingEvents,
  lang,
  viewPast,
  viewUpcoming,
  viewMore,
  viewOne,
  noPastToShow,
  noUpcomingToShow,
}) => {
  const [key, setKey] = useState('upcoming');
  const numOfItemsToDisplay = 5;

  const upcomingExists = upcomingEvents && upcomingEvents.length > 0;

  const [pastEventsList, loadMorePast, hasMorePast, setLoadMorePast] = useLoadMore(
    pastEvents,
    numOfItemsToDisplay
  );

  let upcomingEventsSorted = upcomingEvents.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.eventDate) - new Date(a.eventDate);
  });
  let upcomingEventsSortedRev = upcomingEventsSorted.reverse()
  const [upcomingEventsList, loadMoreUpcoming, hasMoreUpcoming, setLoadMoreUpcoming] = useLoadMore(
    upcomingEventsSortedRev,
    numOfItemsToDisplay
  );
   

  // Responsive breakpoints for Masonry component
  const breakpointColumnObj = {
    default: 3,
    992: 2,
    500: 1,
  };

  // Handle 'load more' button click
  const handleLoadMoreUpcomingClick = () => {
    setLoadMoreUpcoming(true);
  };

  const upcoming = (
    <>
      <Masonry
        breakpointCols={breakpointColumnObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {upcomingEventsList.map((event) => (
          <EventCard key={event.id} event={event} lang={lang} btnText={viewOne} />
        ))}
      </Masonry>
      <PageFooter>
        {hasMoreUpcoming ? (
          <OutlineBtnLg btnText={viewMore} as="button" handleClick={handleLoadMoreUpcomingClick} />
        ) : (
          <OutlineBtnLg btnText={viewMore} as="button" disabled />
        )}
      </PageFooter>
    </>
  );
  const noUpcoming = (
    <section
      className="my-5 py-5 text-center"
      style={{ fontSize: '2rem', textTransform: 'uppercase' }}
    >
      <p>{noUpcomingToShow}</p>
    </section>
  );
  const pastExists = pastEvents && pastEvents.length > 0;

  // Handle 'load more' button click
  const handleLoadMorePastClick = () => {
    setLoadMorePast(true);
  };

  const past = (
    <>
      <Masonry
        breakpointCols={breakpointColumnObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {pastEventsList.map((event) => (
          <EventCard key={event.id} event={event} lang={lang} btnText={viewOne} />
        ))}
      </Masonry>
      <PageFooter>
        {hasMorePast ? (
          <OutlineBtnLg btnText={viewMore} as="button" handleClick={handleLoadMorePastClick} />
        ) : (
          <OutlineBtnLg btnText={viewMore} as="button" disabled />
        )}
      </PageFooter>
    </>
  );
  const noPast = (
    <section
      className="my-5 py-5 text-center"
      style={{ fontSize: '2rem', textTransform: 'uppercase' }}
    >
      <p>{noPastToShow}</p>
    </section>
  );

  return (
    <StyledTabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab eventKey="upcoming" title={viewUpcoming}>
        {upcomingExists ? upcoming : noUpcoming}
      </Tab>
      <Tab eventKey="past" title={viewPast}>
        {pastExists ? past : noPast}
      </Tab>
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)`
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border: none;

  & > a {
    border: none !important;
    border-radius: 0 !important;
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    background-color: ${(props) => props.theme.colors.transparent};
    border: 2px solid ${(props) => props.theme.colors.primary} !important;
    padding: 0.7rem 1.4rem;
    margin: 0 2rem;

    &.nav-link {
      margin-bottom: 1rem;
      @media (min-width: 502px) {
        margin: 0 2rem;
      }
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.black};
      border: 2px solid ${(props) => props.theme.colors.black} !important;
    }

    &::after {
      height: 0;
      width: 0;
    }

    &.active {
      color: ${(props) => props.theme.colors.white} !important;
      background-color: ${(props) => props.theme.colors.primary} !important;
      border: 2px solid ${(props) => props.theme.colors.primary} !important;
    }
  }
`;

export default ControlledTabs;
