import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col, Tabs } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import { mapEdgesToNodes } from '../lib/helpers';
import { localizeDate } from '../lib/helpers';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PageFooter from '../components/PageFooter';
import EventsTabs from '../components/Event/EventsTabs';

const EventsPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const {
    pastEvents,
    upcomingEvents,
    viewEvent,
    loadMoreEvents,
    noPastToShow,
    noUpcomingToShow,
  } = data.eventsDictionary;

  const eventsArray = mapEdgesToNodes(data.events);

  const sortEvents = (eventsList) => {
    const dateToday = new Date(Date.now());
    const testDate = new Date(2021, 12, 31);
    // console.log(testDate);
    let past = [];
    let upcoming = [];

    eventsList.forEach((element) => {
      element.eventDate = new Date(element.eventDate);
      if (element.eventDate < dateToday) {
        past.push(element);
      } else {
        upcoming.push(element);
      }
    });
    return [past, upcoming];
  };
  const sorted = sortEvents(eventsArray);

  const pastEventsList = sorted[0];
  const upcomingEventsList = sorted[1];

  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={pageInfo.heroImg.asset.seoImg} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>
      <Container as="section" style={{ marginTop: '4.875rem' }}>
        <EventsTabs
          pastEvents={pastEventsList}
          upcomingEvents={upcomingEventsList}
          lang={pageContext.locale}
          viewPast={pastEvents}
          viewUpcoming={upcomingEvents}
          viewMore={loadMoreEvents}
          viewOne={viewEvent}
          noPastToShow={noPastToShow}
          noUpcomingToShow={noUpcomingToShow}
        />
      </Container>
      <PageFooter></PageFooter>
    </Layout>
  );
};

export const query = graphql`
  query EventsPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-63c5a03c-21f2-512e-81d7-e2961728aad4" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              seoImg: fluid {
                src
              }
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    eventsDictionary: sanityDictionaryEvent {
      currentEvents {
        _type
        de
        en
      }
      loadMoreEvents {
        _type
        de
        en
      }
      pastEvents {
        _type
        de
        en
      }
      upcomingEvents {
        _type
        de
        en
      }
      viewAllEvents {
        _type
        de
        en
      }
      viewEvent {
        _type
        de
        en
      }
      noPastToShow {
        _type
        de
        en
      }
      noUpcomingToShow {
        _type
        de
        en
      }
    }
    events: allSanityEvent(sort: { fields: eventDate, order: DESC }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          eventDate
          slug {
            current
          }
          location
          address {
            lat
            lng
          }
          _rawShortDescription(resolveReferences: { maxDepth: 5 })
          flyer {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          ticketUrl {
            linkUrl
          }
        }
      }
    }
  }
`;

export default localize(EventsPage);
